/*.logo_desktop{
   height: 70px;
}

.logo_mobile{
  height: 70px;
}*/
/*html, body {
  overscroll-behavior: none;
}*/
nav{
  background-color: #441c2a !important;
}

.layout--nav--menu--text{
  color: white !important;
}

.layout--nav--menu--text:hover{
  transform: scale(1.5);
  transition:all ease-in .25s;
  color: white !important;
}

.layout--footer {
  background-color: #441c2a;
  color: white;
  text-align: center;
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 0;
  left: 0;
}

.layout--navbar--button--reservation, .layout--button--navbar--text{
  color: white;
}

.layout--button--navbar--text{
  margin-right: 15px;
}

.parallax--all--text--h1{
  text-shadow: 1px 1px 2px black;
  font-family: 'Lobster', cursive;
  font-size: 3em;
}

.parallax--all--text--h3{
  text-shadow: 1px 1px 2px black;
  color: #ffffff;
}

.layout--footer--heart{
  color: darkred;
}

.layout--footer--copyright{
  margin-bottom: 0;
}

.fs-buttons{
  position:fixed;
  z-index: 1000;
  width:42px;
  .fs-button{
    display:block;
    width:42px;
    height:42px;
    border-radius:50%;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items:center;
    background:silver;
    color:white;
    text-decoration:none;
    margin-top:.5rem;
    transition:all ease-out .25s;
    font-size: 20px;
    &:hover{
      transform:scale(1.10);
      transition:all ease-in .25s;
    }
  }
  &.on-bottom{
    bottom:1rem;
  }
  &.on-right{
    right:1rem;
  }
  &.on-top{
    top:6rem;
  }
  &.on-left{
    left:1rem;
  }
  .is-instagram{
    background:radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);;
  }
  .is-contact{
    background:#441c2a;
  }
  .is-facebook{
    background:#3B5998;
  }
  .is-menu-of-day{
    background:#e44d42;
  }
  .layout--truckfly{
    height: 25px;
  }
}

.layout--button--sidenav{
  list-style-type: none;
}

.layout--bars--colors{
  color: #FFFFFF;
}

.layout--nabar--title--logo{
  font-family: 'Lobster', cursive;
  color: #ffffff;
}
.layout--side--nav--background{
  background-color: rgba(0,0,0,0.7);
}
.layout--sidenav--li:hover{
  text-decoration: none;
}

.layout--truckfly--button{
  background-color: #e44d42 !important;
}

.contact--title{
  color: #441c2a;
  font-family: 'Lobster', cursive;
}
.linkGth{
  color: white !important;
}
.linkGth:hover{
  text-decoration: none !important;
}